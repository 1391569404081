import React, { useEffect } from 'react';
import {PageView} from '../../tracking';
import {Row, Col} from 'react-bootstrap';
import classNames from 'classnames';

function AboutUs(){

    useEffect(() => {
        PageView();
    }, [])

    return(
        <Row className={classNames("mt-3 bg-white mx-0 rounded")}>
            <Col md={12} className="p-4">
                <h5>ABOUT US</h5><hr/>
                <p>
                    The British Editorial Society of Bone & Joint Surgery is a not-for-profit charity and Company limited by Guarantee.
                </p>
                <p>
                    It was formed in 1953 for “the advancement and improvement of education in orthopaedic surgery and allied branches of surgery”, achieved through publication of The Bone & Joint Journal, and related activities.  Under the imprint Bone & Joint, the Society owns and publishes The Bone & Joint Journal, Bone & Joint Research, Bone & Joint Open and Bone & Joint 360.
                </p>
                <h5><b>Our Offices</b></h5>
                <p className="font-weight-bold">The British Editorial Society of Bone & Joint Surgery</p>
                <p className="mb-0">22 Buckingham Street, London WC2N 6ET, UK</p>
                <p className="mb-0">Tel: +44 (0) 20 7782 0010</p>
                <p>Fax: +44 (0) 20 7782 0995</p>
                <p>Email: <a href="mailto:orthopublish@boneandjoint.org.uk?subject=Query about the information&body=Your query here...">orthopublish@boneandjoint.org.uk</a></p>
                <h5><b>Managing Director</b></h5>
                <p>Richard Hollingsworth</p>
                <h5><b>Director of Publishing</b></h5>
                <p>Emma Vodden</p>
                <h5><b>Head of Marketing & Sales</b></h5>
                <p>Emma Nankervis</p>
            </Col>
        </Row>
    )
}

export default AboutUs;