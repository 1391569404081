import React, { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styles from "./PaymentSuccess.module.scss"
import { useEffect } from "react";
import history from "../../history";
import { useParams } from "react-router-dom";
import AxiosService from "../../utils/AxiosService";

const PaymentFailed = () => {
    const [show, setShow] = useState(true)
    const [counter, setCounter] = useState(5)
    const queryParams = new URLSearchParams(window.location.search)
    const params = window.location.search.split("?")[1].split("&")
    const currency = queryParams.get("currency")
    const journal = queryParams.get("journalName")
    const [journalName, setJournalName] = useState("")
    const [fees, setFees] = useState("")
    const [manuscriptId, setManuscriptId] = useState("")
    const [transactionUpdated, setTransactionUpdated] = useState(false)
    const apiService = new AxiosService()
    const currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };

    useEffect(() => {
        if(transactionUpdated) {
            if(counter > 0) {
                setTimeout(() => {
                    setCounter(counter-1)
                }, 1000)
            } else {
                history.push("/profile?tab=credits")
            }
        }
    }, [counter, transactionUpdated])

    useEffect(() => {
        let manuscriptId = ""
        let body = {
            purchaseType: "",
            sessionId: ""
        }
        params.map((item) => {
            let temp = item.split("=")
            if(temp[0] === "manuscriptId") {
                setManuscriptId(decodeURIComponent(temp[1]))
                manuscriptId = temp[1] 
            } else if (temp[0] === "fees") {
                setFees(decodeURIComponent(temp[1]))
            } else if (temp[0] === "journalName") {
                setJournalName(decodeURIComponent(temp[1])) 
            } else if (temp[0] === "purchaseType") {
                body.purchaseType = temp[1]
            } else if (temp[0] === "sessionId") {
                body.sessionId = temp[1]
            } else if (temp[0] === "templateType") {
                body.templateType = temp[1]
            }
        })
        apiService.updateTransaction(manuscriptId, body).then((res) => {
            setTransactionUpdated(true)
        }, (err) => {
            setTransactionUpdated(true)
        })
    },[])
    return (
        <div className="mt-3 d-flex justify-content-center my-5 py-5">
            <Card className="w-50" show={show} onHide={()=> setShow(true)}>
                <Card.Header className='border-bottom'>
                    <div>
                        <Modal.Title className='d-flex h4 justify-content-center text-danger'>
                            Payment failed
                        </Modal.Title>
                        <span className="text-secondary">{journal ? journal : ""}</span>
                    </div>
                </Card.Header>
                <Card.Body className="">
                    <div>
                        <div className="d-none d-md-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-column p-2">
                                <span className="small text-secondary">SUBMISSION FEES</span>
                                <h4>{currencySymbols[currency.toUpperCase()]+fees}</h4>
                                <span className="small text-secondary">incl. of taxes</span>
                            </div>
                            <div className="d-flex flex-column p-2">
                                <span className="ml-auto small text-secondary">Redirecting in</span>
                                <h4 className="ml-auto">{counter}</h4>
                                <span className="ml-auto small text-secondary">seconds</span>
                            </div>
                        </div>
                        <div className="d-sm-flex d-md-none flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-column p-2">
                                <span className="text-center small text-secondary">SUBMISSION FEES</span>
                                <h4 className="text-center">{currencySymbols[currency.toUpperCase()]+fees}</h4>
                                <span className="text-center small text-secondary">incl. of taxes</span>
                            </div>
                            <div className="d-flex flex-column p-2">
                                <span className="text-center small text-secondary">Redirecting in</span>
                                <h4 className="text-center">{counter}</h4>
                                <span className="text-center small text-secondary">seconds</span>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default PaymentFailed