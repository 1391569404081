import React from "react";
import { Modal } from "react-bootstrap";
import { FieldLoader } from "../../../components/Loader/CommonLoader";

const PaymentModal = ({show, setShow, manuscriptId = "", loader, setLoader, handleClone, createDefaultManuscript, genericTemplate, journalTitle}) => {

	return(
		<Modal size="md" show={show.isPaymentCheck} onHide={()=> {
            setShow({...show, isPaymentCheck: false})
            setLoader({...loader, isClone:false, isDefault:false})
        }}>
		<Modal.Header closeButton className='border-bottom'>
			<div>
                <Modal.Title className='h5'>
                    Submission Fees
                </Modal.Title>
                <span className="text-secondary">{journalTitle}</span>
            </div>
		</Modal.Header>
		<Modal.Body className="">
			<div>
				
				<p className={'message'}>
                    *sample text* We ensure that manuscripts being submitted to a journal are held to a high standard and thorough checks and verifications are done to ensure this. To cover the costs of verification and ensuring manuscripts are of a high quality, a submission fee is charged. Please note that this fee is charged every time a manuscript is submitted to a journal, irrespective of its content. *sample text*.
                </p>
                <p className={'message'}>
                    Please find the respective fees for submitting to this journal below. Once the fee has been successfully paid, you will be redirected to the format process page. 
                </p>
                <div className="border rounded d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column p-2">
                        <span className="small text-secondary">SUBMISSION FEES</span>
                        <h4>$100</h4>
                        <span className="small text-secondary">incl. of taxes</span>
                    </div>
                    <div className="p-2">
                        {manuscriptId ?
                            loader.isClone ? 
                                <FieldLoader className="w-100" />
                            :
                                <button className="btn-lg w-100" onClick={handleClone}>Make payment</button>
                        :
                            loader.isDefault ? 
                                <FieldLoader className="w-100" />
                            :
                                <button className="btn-lg  w-100" onClick={createDefaultManuscript}>Make payment</button>
                        }
                    </div>
                </div>
				{/* <section>
					<button className={'w-100 mb-2 customButton'} onClick={()=> setShow({...show, isPaymentCheck: false})}>Cancel</button>
					{manuscriptId?
					    loader.isClone ? 
					        <FieldLoader className="w-100" />
					    :
                            <button className="w-100" onClick={handleClone}>Continue formatting</button>
					:
                        loader.isDefault ? 
					        <FieldLoader className="w-100" />
					    :
                            <button className="w-100" onClick={createDefaultManuscript}>Continue formatting</button>
					}
				</section> */}
			</div>
		</Modal.Body>
		</Modal>
	)
}

export default PaymentModal;